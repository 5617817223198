import WebLayout from '@/modules/core/components/Layout'

export const Web = ({
  Component,
  deviceMode,
  layout,
  seoTags,
  userProfile,
  isApp,
  shouldReloadPage,
  features,
  pageProps,
}: any) => (
  <WebLayout
    deviceMode={deviceMode}
    seoTags={seoTags}
    userProfile={userProfile}
    isApp={isApp}
    shouldReloadPage={shouldReloadPage}
    itemCounters={layout.itemCounters}
    features={features}
  >
    <Component {...pageProps} />
  </WebLayout>
)
